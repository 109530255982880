import React from 'react';
import moment from 'moment';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import { MyInfoForm } from '@mous221/hk-components';

export class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFormIsOpen: false,
      picture: false,
    };
  }

  setProfilePic = (pic) => {
    this.setState({ picture: pic });
  };

  handleEditFormToggle = () => {
    this.setState({ editFormIsOpen: !this.state.editFormIsOpen });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      updateProfile,
      form: { validateFields },
    } = this.props;
    const { picture } = this.state;
    const { editFormIsOpen } = this.state;

    validateFields((err, values) => {
      if (picture) {
        values.picture = values.picture.fileList[0].originFileObj;
      } else {
        values.picture = null;
      }

      if (!err) {
        console.log(values);

        updateProfile(values);
        this.setState({ editFormIsOpen: !editFormIsOpen });
      }
    });
  };

  handleCancel = (e) => {
    e.preventDefault();

    const {
      form: { setFields },
      user,
    } = this.props;
    const { editFormIsOpen } = this.state;

    setFields({
      email: {
        value: user.email,
      },
      mobile: {
        value: user.mobile,
      },
      dateOfBirth: {
        value: moment.unix(user.dateOfBirth),
      },
      gender: {
        value: user.gender,
      },
    });
    this.setState({ editFormIsOpen: !editFormIsOpen });
  };

  render() {
    const { user, form } = this.props;
    const { editFormIsOpen } = this.state;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <MyInfoForm
            user={user}
            form={form}
            editFormIsOpen={editFormIsOpen}
            handleEditFormToggle={this.handleEditFormToggle}
            handleCancel={this.handleCancel}
            consultant={false}
            setProfilePic={this.setProfilePic}
            withOutDateAndGenter={true}
          />
        </Form>
      </div>
    );
  }
}

const WrappedUserInfo = Form.create({ name: 'user_info_form' })(UserInfo);

export default WrappedUserInfo;

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
};
