import { alertConstants, userConstants } from 'redux/actions/types';

const alert = (state = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
      };
    case alertConstants.INFO:
      return {
        type: 'info',
        message: action.message,
      };
    case alertConstants.WARNING:
      return {
        type: 'warning',
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.message,
      };
    case alertConstants.CLEAR:
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default alert;
