const handleErrors = (errors) => {
  let errorsArr = [];
  if (Array.isArray(errors)) {
    errorsArr = errors;
  } else if (typeof errors === 'string') {
    errorsArr.push(errors);
  } else if (errors instanceof Object && !Array.isArray(errors)) {
    if (Object.keys(errors).length !== 0) {
      Object.keys(errors).map((key) => {
        if (key === 'message') {
          return errorsArr.push(`${errors[key]}`);
        } else {
          return errorsArr.push(`${key}: ${errors[key]}`);
        }
      });
    }
  }
  return errorsArr;
};

export default handleErrors;
