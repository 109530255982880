const patientScansConstants = {
  GET_SCAN_REQUEST: 'GET_SCAN_REQUEST',
  GET_SCAN_SUCCESS: 'GET_SCAN_SUCCESS',
  GET_SCAN_FAILURE: 'GET_SCAN_FAILURE',
  GET_SCANS_REQUEST: 'GET_SCANS_REQUEST',
  GET_SCANS_SUCCESS: 'GET_SCANS_SUCCESS',
  GET_SCANS_FAILURE: 'GET_SCANS_FAILURE',
  SEARCH_SCAN_REQUEST: 'SEARCH_SCAN_REQUEST',
  SEARCH_SCAN_SUCCESS: 'SEARCH_SCAN_SUCCESS',
  SEARCH_SCAN_FAILURE: 'SEARCH_SCAN_FAILURE',
  STORE_SCAN_REQUEST: 'STORE_SCAN_REQUEST',
  STORE_SCAN_SUCCESS: 'STORE_SCAN_SUCCESS',
  STORE_SCAN_FAILURE: 'STORE_SCAN_FAILURE',
  REQUEST_STORE_SCAN_REQUEST: 'REQUEST_STORE_SCAN_REQUEST',
  REQUEST_STORE_SCAN_SUCCESS: 'REQUEST_STORE_SCAN_SUCCESS',
  REQUEST_STORE_SCAN_FAILURE: 'REQUEST_STORE_SCAN_FAILURE',
  UPLOAD_SCAN_REQUEST: 'UPLOAD_SCAN_REQUEST',
  UPLOAD_SCAN_SUCCESS: 'UPLOAD_SCAN_SUCCESS',
  UPLOAD_SCAN_FAILURE: 'UPLOAD_SCAN_FAILURE',
  GET_UPLOADED_SCANS_REQUEST: 'GET_UPLOADED_SCANS_REQUEST',
  GET_UPLOADED_SCANS_SUCCESS: 'GET_UPLOADED_SCANS_SUCCESS',
  GET_UPLOADED_SCANS_FAILURE: 'GET_UPLOADED_SCANS_FAILURE',
  CLEAR_DATA: 'CLEAR_DATA',
};

export default patientScansConstants;
