import { combineReducers } from 'redux';
import { default as alert } from './alerts/alertsReducer';
import { default as authentication } from './user/userReducer';
import { default as scans } from './patientScans/patientScansReducer';
const appReducer = combineReducers({
  alert,
  authentication,
  scans,
});

export default appReducer;
