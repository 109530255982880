import React, { Component } from 'react';
import { Form, Input, DatePicker } from 'antd';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Colors } from '@mous221/hk-utils';

const FormItem = Form.Item;
const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.secondaryColor};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 15px;
  font-weight: 500;
  min-width: 247px;
  line-height: 1;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-bottom: 0;
    min-width: 1px;
  }
`;

class SearchForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      handleSearch,
      setSearchDate,
      setName,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        const { name, date } = values;
        const searchDate = date ? moment(date).unix() : null;
        setSearchDate(searchDate);
        setName(name);
        handleSearch(null, name, searchDate);
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('name', {})(<Input placeholder="Name" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator(
              'date',
              {}
            )(<DatePicker format="Do MMM YY" placeholder="Date" />)}
          </FormItem>
          <SubmitButton className="submit-button" htmlType="submit">
            Search
          </SubmitButton>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(SearchForm);

SearchForm.propTypes = {
  form: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setSearchDate: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
};
