import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { ScansPageComponent } from './components';
import { MainLayout } from '@mous221/hk-components';

const ScansPage = (props) => (
  <MainLayout
    footer="main"
    children={<ScansPageComponent {...props} />}
    {...props}
  />
);

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(ScansPage);
