import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import {
  ProfilePic,
  convertDateToFullStringDate,
  SecondaryButton,
  PrimaryButton,
  CapitalizeFirstChart,
} from '@mous221/hk-utils';
import { Form, Input } from 'antd';

import { CheckIcon } from 'images';
import { patientScansActions } from 'redux/actions';
import { Link } from 'react-router-dom';

const RequestWrapper = styled.div`
  border-radius: 16px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background: #fff;
  margin-bottom: 40px;
  width: 98%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 6px 6px 0 0;
    a {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #5cb3db;
    }
    .scan-info {
      h2 {
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #060c2b;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 14.4px;
        color: #9298ac;
      }
    }
  }
  .content {
    padding: 10px 18px;
    .body {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      color: #060c2b;
    }
  }
  .consultant-info {
    padding: 10px 15px 20px;
    display: flex;
    align-items: center;
    p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #060c2b;
      margin: 0;
      margin-left: 10px;
    }
  }
  .footer {
    background: #f6f6f6;
    padding: 25px 0;
    text-align: center;
    border-radius: 0 0 16px 16px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 10px;
      }
    }
  }
`;

const FormWrapper = styled.div`
  form {
    width: 90%;
    display: flex;
    margin: auto;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    button {
      padding: 10px;
      &:disabled {
        background: red;
      }
    }
    .ant-form-item {
      width: 70%;
      margin-right: 30px;
      .ant-form-item-control {
        input {
          height: 49px;
        }
      }
    }
  }
`;
export class Scan extends Component {
  static propTypes = {
    scan: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    storeScan: PropTypes.func.isRequired,
    patientId: PropTypes.number.isRequired,
    setScan: PropTypes.func.isRequired,
  };
  state = {
    storing: false,
    sentPrice: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      storeScan,
      scan,
      patientId,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        const sortScan = {
          consultant_scan_request_id: scan.id,
          price: values.price,
          patient_id: patientId,
        };
        storeScan(sortScan);
        this.setState({ storing: false, sentPrice: true });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      scan,
      setScan,
    } = this.props;
    const { storing, sentPrice } = this.state;

    return (
      <RequestWrapper>
        <Link to="#!" onClick={() => setScan(scan)}>
          <header className="header">
            <div className="scan-info">
              <h2>
                {scan.title
                  ? CapitalizeFirstChart(scan.title)
                  : CapitalizeFirstChart(
                      scan.scan_type.replace('-', ' ').replace('_', ' ')
                    )}
              </h2>
              <p className="time">
                {convertDateToFullStringDate(scan.created_at)}
              </p>
            </div>
            <div className="consultant-info">
              <ProfilePic
                user={scan.consultant}
                style={{ borderRadius: '50%' }}
                width="25"
                height="25"
              />
              <p>Dr.{CapitalizeFirstChart(scan.consultant.name)}</p>
            </div>
          </header>
          <div className="content">
            <p className="body">{scan.body ? scan.body : `There is no Body`}</p>
          </div>
        </Link>
        <footer className="footer">
          {storing ? (
            <>
              <FormWrapper>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item>
                    {getFieldDecorator('price', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Scan Price!',
                        },
                      ],
                    })(<Input type="number" placeholder="Scan Price" />)}
                  </Form.Item>
                  <PrimaryButton minWidth={'250'}>
                    <button htmltype="submit">Send</button>
                  </PrimaryButton>
                </Form>
              </FormWrapper>
            </>
          ) : (
            <SecondaryButton minWidth={'630'}>
              <button
                onClick={() => {
                  this.setState({ storing: true });
                }}
                disabled={sentPrice}
              >
                <CheckIcon />
                <span>Accept</span>
              </button>
            </SecondaryButton>
          )}
        </footer>
      </RequestWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  scanResult: state.scans.scan,
});

const mapDispatchToProps = {
  storeScan: (scan) => patientScansActions.storeScan(scan),
};

export default Form.create({ name: 'store-scan' })(
  connect(mapStateToProps, mapDispatchToProps)(Scan)
);
