import { patientScansConstants } from 'redux/actions/types';

const initialState = {
  scans: {
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path: 'https://api.hk.phantasm.biz/api/v1/laboratory/scans',
      per_page: 15,
      to: 1,
      total: 0,
    },
  },
  scan: {},
  loading: false,
};

function patientScans(state = initialState, action) {
  switch (action.type) {
    case patientScansConstants.GET_SCAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case patientScansConstants.GET_SCAN_SUCCESS:
      return {
        ...state,
        loading: false,
        scan: action.scan.data,
      };
    case patientScansConstants.GET_SCAN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientScansConstants.GET_SCANS_REQUEST:
      return {
        ...state,
        getScans: true,
        loading: true,
      };
    case patientScansConstants.GET_SCANS_SUCCESS:
      return {
        ...state,
        gotScans: true,
        getScans: false,
        loading: false,
        scans: action.scans,
      };
    case patientScansConstants.GET_SCANS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientScansConstants.SEARCH_SCAN_REQUEST:
      return {
        ...state,
        getScans: true,
        loading: true,
      };
    case patientScansConstants.SEARCH_SCAN_SUCCESS:
      return {
        ...state,
        gotScans: true,
        getScans: false,
        errors: null,
        loading: false,
        searchScans: action.scans,
      };
    case patientScansConstants.SEARCH_SCAN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.error,
      };
    case patientScansConstants.STORE_SCAN_REQUEST:
      return {
        ...state,
        addScan: true,
        loading: true,
      };
    case patientScansConstants.STORE_SCAN_SUCCESS:
      return {
        ...state,
        addedScan: true,
        addScan: false,
        scans: action.scans,
        loading: false,
        searchScans: {
          ...state.searchScans,
          data: state.searchScans.data.filter((scan) => scan.id !== action.id),
        },
      };
    case patientScansConstants.STORE_SCAN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientScansConstants.REQUEST_STORE_SCAN_REQUEST:
      return {
        ...state,
        addScan: true,
        loading: true,
      };
    case patientScansConstants.REQUEST_STORE_SCAN_SUCCESS:
      return {
        ...state,
        addedScan: true,
        addScan: false,
        scanRequest: action.scan,
        loading: false,
      };
    case patientScansConstants.REQUEST_STORE_SCAN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientScansConstants.UPLOAD_SCAN_REQUEST:
      return {
        ...state,
        uploadScan: true,
        loading: true,
      };
    case patientScansConstants.UPLOAD_SCAN_SUCCESS:
      return {
        ...state,
        uploadedScan: true,
        uploadScan: false,
        scans: action.scan,
        loading: false,
      };
    case patientScansConstants.UPLOAD_SCAN_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
        loading: false,
      };
    case patientScansConstants.GET_UPLOADED_SCANS_REQUEST:
      return {
        ...state,
        getUploadedScan: true,
        loading: true,
      };
    case patientScansConstants.GET_UPLOADED_SCANS_SUCCESS:
      return {
        ...state,
        gotUploadedScan: true,
        getUploadedScan: false,
        loading: false,
        scans: action.scan,
      };
    case patientScansConstants.GET_UPLOADED_SCANS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientScansConstants.CLEAR_DATA:
      return {
        scans: {
          data: [],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: 'https://api.hk.phantasm.biz/api/v1/laboratory/scans',
            per_page: 15,
            to: 1,
            total: 0,
          },
        },
        scan: {},
        loading: false,
      };
    default:
      return state;
  }
}

export default patientScans;
