import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Form, Input, Upload, Modal } from 'antd';
import {
  ProfilePic,
  PrimaryButton,
  convertDateToFullStringDate,
  getBase64,
} from '@mous221/hk-utils';

import { patientScansActions } from 'redux/actions';
import { CamraIcon } from 'images';

const ScanWrapper = styled.div``;
const RequestWarpper = styled.div`
  border-radius: 16px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background: #fff;
  margin-bottom: 40px;
  width: 98%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 6px 6px 0 0;
    a {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #5cb3db;
    }
    .scan-info {
      h2 {
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #060c2b;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 14.4px;
        color: #9298ac;
      }
    }
  }
  .content {
    padding: 10px 18px;
    .body {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      color: #060c2b;
    }
  }
  .consalutant-info {
    padding: 10px 15px 20px;
    display: flex;
    align-items: center;
    p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #060c2b;
      margin: 0;
      margin-left: 10px;
    }
  }
  .footer {
    background: #f6f6f6;
    padding: 25px 0;
    text-align: center;
    border-radius: 0 0 16px 16px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 10px;
      }
    }
  }
`;
const AddScanWrapper = styled.div`
  .back {
    font-family: LucidaGrande;
    font-size: 18px;
    color: #060c2b;
    margin-bottom: 20px;
    display: block;
  }
`;
const FormWrapper = styled.div`
  padding: 15px;
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .ant-row.ant-form-item {
    max-width: 780px;
  }
  .ant-form-item-label {
    display: none;
  }
  .ant-input {
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    padding: 15px;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 500;
    min-height: 43px;
  }
  .has-error {
    .ant-input {
      border: 1px solid red;
    }
  }
  .text-area {
    min-height: 219px;
    font-family: Heebo;
    font-size: 17px;
    font-weight: 500;
  }
  .primary-button {
    width: 100%;
    button {
      padding: 5px;
    }
  }
  .upload-button {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px;
  }

  .ant-upload-text {
    margin-left: 10px;
    font-family: Heebo;
    font-size: 15px;
    color: #5cb3db;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    display: block;
    width: 100%;
    text-align: center;
    height: auto;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card .ant-upload {
    display: inline-block;
  }
`;

export class addScan extends Component {
  static propTypes = {
    scan: PropTypes.object.isRequired,
    setView: PropTypes.func.isRequired,
    uploadScan: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    scanId: PropTypes.number.isRequired,
  };

  state = {
    inputScanFiles: false,
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };
  handleCancelUplaod = () => this.setState({ previewVisible: false });

  handleChangeUplaod = ({ fileList }) => this.setState({ fileList });

  handlePreviewUplaod = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleBeforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      uploadScan,
    } = this.props;
    const { fileList } = this.state;
    const uploadScanData = new FormData();
    if (fileList) {
      fileList.forEach((file) => {
        uploadScanData.append('scan_file', file.originFileObj, file.name);
      });
    }

    validateFields((err, values) => {
      if (!err) {
        uploadScanData.append('note', values.note);
        uploadScanData.append('title', values.title);
        uploadScanData.append('lab_scan_id', `${this.props.scanId}`);

        uploadScan(uploadScanData);
      }
    });
  };
  render() {
    const {
      scan,
      form: { getFieldDecorator },
    } = this.props;
    console.log(scan);
    const {
      previewVisible,
      previewImage,
      fileList,
      inputScanFiles,
    } = this.state;
    const isMriOrCT = Boolean(
      scan.scan_type === 'radiology-ct' || scan.scan_type === 'radiology-mri'
    );
    const uploadButton = (
      <div className="upload-button">
        <CamraIcon />
        <div className="ant-upload-text">
          {isMriOrCT ? 'Add Dicom Files' : 'Add images'}
        </div>
      </div>
    );
    return (
      <AddScanWrapper>
        <Link className="back" to="/">
          Back to Requests
        </Link>
        <ScanWrapper>
          <RequestWarpper>
            <header className="header">
              <div className="scan-info">
                <h2>
                  {scan.title
                    ? scan.title
                    : scan.scan_type
                        .replace('-', ' ')
                        .replace('_', ' ')
                        .replace('radiology', '')}
                </h2>
                <p className="time">
                  {convertDateToFullStringDate(scan.created_at)}
                </p>
              </div>
              {scan.consultant && (
                <div className="consalutant-info">
                  <ProfilePic
                    user={scan.consultant}
                    style={{ borderRadius: '50%' }}
                    width="25"
                    height="25"
                  />
                  <p>Dr.{scan.consultant.name}</p>
                </div>
              )}
            </header>
            <div className="content">
              <p className="body">
                {scan.body ? scan.body : `There is no Body`}
              </p>
            </div>

            <footer className="footer">
              {inputScanFiles ? (
                <>
                  <FormWrapper>
                    <Form onSubmit={this.handleSubmit}>
                      <div>
                        <Form.Item label="Title">
                          {getFieldDecorator('title', {
                            rules: [
                              {
                                required: true,
                                message: `Please input your ${scan.scan_type
                                  .replace('-', ' ')
                                  .replace('_', ' ')} Title!`,
                              },
                            ],
                          })(<Input placeholder="Title" />)}
                        </Form.Item>
                        <Form.Item label="Note">
                          {getFieldDecorator(
                            'note',
                            {}
                          )(
                            <Input.TextArea
                              placeholder="Note"
                              className="text-area"
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          label={`Upload ${scan.scan_type
                            .replace('-', ' ')
                            .replace('_', ' ')
                            .replace('radiology', '')}`}
                        >
                          {getFieldDecorator('uplaod-medcal-report', {
                            rules: [
                              {
                                required: true,
                                message: `Please upload ${scan.scan_type
                                  .replace('-', ' ')
                                  .replace('_', ' ')
                                  .replace('radiology', '')}!`,
                              },
                            ],
                          })(
                            <div className="clearfix">
                              <Upload
                                listType="picture-card"
                                multiple={true}
                                fileList={fileList}
                                onPreview={this.handlePreviewUplaod}
                                onChange={this.handleChangeUplaod}
                                beforeUpload={this.handleBeforeUpload}
                                accept={
                                  isMriOrCT
                                    ? 'image/dcmd, image/dcm, image/dicom'
                                    : 'image/png, image/jpeg'
                                }
                              >
                                {uploadButton}
                              </Upload>
                              <Modal
                                visible={previewVisible}
                                footer={null}
                                onCancel={this.handleCancelUplaod}
                              >
                                <img
                                  alt="example"
                                  style={{ width: '100%' }}
                                  src={previewImage}
                                />
                              </Modal>
                            </div>
                          )}
                        </Form.Item>
                        <Form.Item>
                          <PrimaryButton>
                            <button htmltype="submit" className="submit">
                              Add
                            </button>
                          </PrimaryButton>
                        </Form.Item>
                      </div>
                    </Form>
                  </FormWrapper>
                </>
              ) : (
                <>
                  <PrimaryButton minWidth="630">
                    <button
                      onClick={() => {
                        this.setState({ inputScanFiles: true });
                      }}
                    >
                      Add{' '}
                      {scan.scan_type
                        .replace('-', ' ')
                        .replace('_', ' ')
                        .replace('radiology', '')}{' '}
                      Test
                    </button>
                  </PrimaryButton>
                </>
              )}
            </footer>
          </RequestWarpper>
        </ScanWrapper>
      </AddScanWrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  uploadScan: (scan) => patientScansActions.uploadScan(scan),
};

export default Form.create({ name: 'add Lab test' })(
  connect(mapStateToProps, mapDispatchToProps)(addScan)
);
