import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H1, ProfilePic, CapitalizeFirstChart } from '@mous221/hk-utils';
import styled from '@emotion/styled';
import ReadMoreAndLess from 'react-read-more-less';

import { patientScansActions } from 'redux/actions';
import { Link } from 'react-router-dom';

const RequestWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background: #fff;
  margin-bottom: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #f9fafc;
    border-radius: 6px 6px 0 0;
    a {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #5cb3db;
    }
    .patient-info {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
      }
    }
    .patient-name {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #060c2b;
      margin-left: 10px;
      line-height: 1.5;
    }
    .patient-code {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #9298ac;
      margin-left: 10px;
      line-height: 1;
    }
  }
  .content {
    padding: 10px 18px;
    h2 {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 18px;
      font-weight: bold;
      color: #060c2b;
      margin-bottom: 0;
    }
    .time {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      color: #9298ac;
      margin-bottom: 10px;
    }
    .short-text {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      color: #9298ac;
    }
    .readMoreText {
      color: #5cb3db !important;
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .consalutant-info {
    padding: 10px 15px 20px;
    display: flex;
    align-items: center;
    p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #060c2b;
      margin: 0;
      margin-left: 10px;
    }
  }
`;

const ActiveRequestWrapper = styled.div`
  h1 {
    margin-top: 30px;
    margin-bottom: 16px;
  }
`;

export class ActiveRequest extends Component {
  componentDidMount() {
    const { getScans } = this.props;

    getScans();
  }
  static propTypes = {
    getScans: PropTypes.func.isRequired,
    scans: PropTypes.object,
  };

  render() {
    const { scans } = this.props;
    return (
      <ActiveRequestWrapper>
        {scans && scans.data && scans.data.length > 0 && (
          <>
            <H1>Active Request</H1>
            {scans.data.map((scan) => (
              <div key={JSON.stringify(scan)}>
                {scan.consultant_scan_request && !scan.scan_file && (
                  <RequestWrapper>
                    <div className="header">
                      <div className="patient-info">
                        <div>
                          <ProfilePic
                            user={scan.patient}
                            style={{ borderRadius: '50%' }}
                            width="25"
                            height="25"
                          />
                        </div>
                        <div>
                          <p className="patient-name">
                            {CapitalizeFirstChart(scan.patient.firstName)}{' '}
                            {CapitalizeFirstChart(scan.patient.lastName)}
                          </p>
                          <p className="patient-code">{scan.patient.mobile}</p>
                        </div>
                      </div>
                      <div>
                        <Link
                          to={`/patient-scans/${scan.patient.mobile}/${scan.id}`}
                        >
                          Add Lab Test
                        </Link>
                      </div>
                    </div>
                    <div className="content">
                      <h2>
                        {scan.consultant_scan_request &&
                        scan.consultant_scan_request.title
                          ? CapitalizeFirstChart(
                              scan.consultant_scan_request.title
                            )
                          : scan.consultant_scan_request &&
                            CapitalizeFirstChart(
                              scan.consultant_scan_request.scan_type
                                .replace('-', ' ')
                                .replace('_', ' ')
                            )}
                      </h2>
                      <p className="time">Added on: {scan.date}</p>
                      <ReadMoreAndLess
                        ref={
                          scan.consultant_scan_request &&
                          scan.consultant_scan_request.body
                        }
                        charLimit={110}
                        readMoreText="More"
                        readLessText=" Less"
                        className="read-more"
                      >
                        {scan.consultant_scan_request &&
                        scan.consultant_scan_request.body
                          ? scan.consultant_scan_request.body
                          : `There is no Body`}
                      </ReadMoreAndLess>
                    </div>
                    {scan.consultant_scan_request &&
                      scan.consultant_scan_request.consultant && (
                        <div className="consalutant-info">
                          <ProfilePic
                            user={
                              scan.consultant_scan_request &&
                              scan.consultant_scan_request.consultant
                            }
                            style={{ borderRadius: '50%' }}
                            width="25"
                            height="25"
                          />
                          <p>
                            Dr.
                            {scan.consultant_scan_request &&
                              scan.consultant_scan_request.consultant &&
                              CapitalizeFirstChart(
                                scan.consultant_scan_request.consultant.name
                              )}
                          </p>
                        </div>
                      )}
                  </RequestWrapper>
                )}
              </div>
            ))}
          </>
        )}
      </ActiveRequestWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { scans } = state.scans;
  return {
    scans,
  };
};

const mapDispatchToProps = {
  getScans: () => patientScansActions.getScans(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveRequest);
