import { alertActions } from 'redux/actions';
import { patientScansServices } from 'services';
import patientScansConstants from './types';
import { history } from 'utils';

const getScan = (id) => {
  return (dispatch) => {
    dispatch(getScanRequest());

    patientScansServices.getScan(id).then((res) => {
      if (res.error) {
        dispatch(getScanFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getScanSuccess(res.scan));
      }
    });
  };
};

const getScanRequest = () => ({
  type: patientScansConstants.GET_SCAN_REQUEST,
});
const getScanSuccess = (scan) => ({
  type: patientScansConstants.GET_SCAN_SUCCESS,
  scan: scan,
});
const getScanFailure = (error) => ({
  type: patientScansConstants.GET_SCAN_FAILURE,
  error,
});

const getScans = (page = null) => {
  return (dispatch) => {
    dispatch(getScansRequest());

    patientScansServices.getScans(page).then((res) => {
      if (res.error) {
        dispatch(getScansFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getScansSuccess(res.scans));
      }
    });
  };
};

const getScansRequest = () => ({
  type: patientScansConstants.GET_SCANS_REQUEST,
});
const getScansSuccess = (scans) => ({
  type: patientScansConstants.GET_SCANS_SUCCESS,
  scans: scans,
});
const getScansFailure = (error) => ({
  type: patientScansConstants.GET_SCANS_FAILURE,
  error,
});

const searchScan = (mobile) => {
  return (dispatch) => {
    dispatch(searchScanRequest());

    patientScansServices.searchScan(mobile).then((res) => {
      if (res.error) {
        dispatch(searchScanFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(searchScanSuccess(res));
      }
    });
  };
};

const searchScanRequest = () => ({
  type: patientScansConstants.SEARCH_SCAN_REQUEST,
});
const searchScanSuccess = (scans) => ({
  type: patientScansConstants.SEARCH_SCAN_SUCCESS,
  scans: scans.scans,
});
const searchScanFailure = (error) => ({
  type: patientScansConstants.SEARCH_SCAN_FAILURE,
  error,
});

const storeScan = (scan) => {
  return (dispatch) => {
    dispatch(storeScanRequest());
    console.log(scan);
    patientScansServices.storeScan(scan).then((res) => {
      if (res.error) {
        dispatch(storeScanFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(alertActions.success('Store Success'));
        dispatch(storeScanSuccess(res, scan.consultant_scan_request_id));
      }
    });
  };
};

const storeScanRequest = () => ({
  type: patientScansConstants.STORE_SCAN_REQUEST,
});
const storeScanSuccess = (scan, consultant_scan_request_id) => ({
  type: patientScansConstants.STORE_SCAN_SUCCESS,
  id: consultant_scan_request_id,
  scan,
});
const storeScanFailure = (error) => ({
  type: patientScansConstants.STORE_SCAN_FAILURE,
  error,
});

const RequestScan = (scan) => {
  return (dispatch) => {
    dispatch(RequestScanRequest());
    console.log(scan);
    patientScansServices.RequestScan(scan).then((res) => {
      if (res.error) {
        dispatch(RequestScanFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(alertActions.success('Store Success'));
        dispatch(RequestScanSuccess(res.scan));
      }
    });
  };
};

const RequestScanRequest = () => ({
  type: patientScansConstants.REQUEST_STORE_SCAN_REQUEST,
});
const RequestScanSuccess = (scan) => ({
  type: patientScansConstants.REQUEST_STORE_SCAN_SUCCESS,
  scan,
});
const RequestScanFailure = (error) => ({
  type: patientScansConstants.REQUEST_STORE_SCAN_FAILURE,
  error,
});

const uploadScan = (scan) => {
  return (dispatch) => {
    dispatch(uploadScanRequest());

    patientScansServices.uploadScan(scan).then((res) => {
      if (res.error) {
        dispatch(uploadScanFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(alertActions.success('Medical Report has been Uploaded'));
        dispatch(uploadScanSuccess(res));
        history.push('/');
      }
    });
  };
};

const uploadScanRequest = () => ({
  type: patientScansConstants.UPLOAD_SCAN_REQUEST,
});
const uploadScanSuccess = (scan) => ({
  type: patientScansConstants.UPLOAD_SCAN_SUCCESS,
  scan,
});
const uploadScanFailure = (error) => ({
  type: patientScansConstants.UPLOAD_SCAN_FAILURE,
  error,
});

const getUploadedScans = (scan) => {
  return (dispatch) => {
    dispatch(getUploadedScansRequest());

    patientScansServices.getUploadedScans(scan).then((res) => {
      if (res.error) {
        dispatch(getUploadedScansFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getUploadedScansSuccess(res));
      }
    });
  };
};

const getUploadedScansRequest = () => ({
  type: patientScansConstants.GET_UPLOADED_SCANS_REQUEST,
});
const getUploadedScansSuccess = (scans) => ({
  type: patientScansConstants.GET_UPLOADED_SCANS_SUCCESS,
  scans,
});
const getUploadedScansFailure = (error) => ({
  type: patientScansConstants.GET_UPLOADED_SCANS_FAILURE,
  error,
});
const clearData = () => ({
  type: patientScansConstants.CLEAR_DATA,
});

const patientScansActions = {
  getScan,
  getScans,
  searchScan,
  storeScan,
  RequestScan,
  uploadScan,
  getUploadedScans,
  clearData,
};

export default patientScansActions;
