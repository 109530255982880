const login = (email, password) => {
  const raw = JSON.stringify({ email: email, password: password });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/laboratory/login`, requestOptions)
    .then((user) => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('userType', 'laboratory');
      }
      return { error: false, user: user };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const updateProfilePicture = (picture) => {
  const formdata = new FormData();
  formdata.append('picture', picture, picture.name);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  return fetch(`/laboratory/profile-picture`, requestOptions)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'laboratory');
      return { error: false, user: user };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const updateProfile = (userInfo) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const { name, mobile, email, city, country, street, picture } = userInfo;
  const user = JSON.stringify({
    name: name ? name : currentUser.name,
    mobile: mobile ? mobile : currentUser.mobile,
    email: email ? email : currentUser.email,
    city: city ? city : currentUser.city,
    country: country ? country : currentUser.country,
    street: street ? street : currentUser.street,
  });

  if (picture) {
    updateProfilePicture(picture, currentUser.token);
  }
  const requestOptions = {
    method: 'PATCH',
    body: user,
    redirect: 'follow',
  };

  return fetch(`/laboratory/profile`, requestOptions)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'laboratory');
      return { error: false, user: user };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const forgotPassword = (email) => {
  const raw = JSON.stringify({ email: email });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`/laboratory/forgot-password`, requestOptions)
    .then((response) => {
      return { success: response.message };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const changePassword = (oldPassword, password) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      oldPassword: oldPassword,
      password: password,
    }),
  };

  return fetch(`/laboratory/change-password`, requestOptions)
    .then((res) => {
      return { error: false, user: res };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getProfile = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/laboratory/profile`, requestOptions)
    .then((profile) => {
      return { error: false, profile };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const refreshToken = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/laboratory/refresh-token`, requestOptions)
    .then((token) => {
      return { error: false, token };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('userType');
};

const userServices = {
  login,
  logout,
  forgotPassword,
  changePassword,
  updateProfile,
  updateProfilePicture,
  getProfile,
  refreshToken,
};

export default userServices;
