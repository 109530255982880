import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from 'antd';
import {
  Container,
  PatientInfo,
  RequestMedicalRecordForm,
} from '@mous221/hk-components';
import {
  ProfilePic,
  CapitalizeFirstChart,
  Add,
  Modal,
} from '@mous221/hk-utils';

import { history, isEmpty } from 'utils';
import { Scan, AddScan } from './';
import { patientScansActions } from 'redux/actions';
import { Lab } from 'images';

const HeaderWrapper = styled.div`
  background: #fff;
  padding: 30px 0;
  margin-top: -3em;
  margin-bottom: 30px;
  h1 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .add-wrappar {
    img {
      left: -5px;
    }
  }
`;
const PatientProfileWrapper = styled.div`
  display: flex;
  img {
    margin-right: 10px;
  }
  p {
    margin-bottom: 0;
    font-weight: 500;
    &.name {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 18px;
      color: #060c2b;
    }
    &.mobile {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      color: #9298ac;
    }
  }
`;
const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export class ScansPage extends Component {
  static propTypes = {
    searchScan: PropTypes.func.isRequired,
    errors: PropTypes.string,
    searchScans: PropTypes.object,
    match: PropTypes.object,
    scan: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getScan: PropTypes.func.isRequired,
    RequestScan: PropTypes.func.isRequired,
  };

  state = {
    currentScan: new URLSearchParams(history.location.search).get('scan')
      ? JSON.parse(new URLSearchParams(history.location.search).get('scan'))
      : null,
    vewScan: false,
    showModal: false,
  };
  componentDidMount() {
    const {
      match: {
        params: { mobileNum, id },
      },
      searchScan,
      getScan,
    } = this.props;
    if (!id) {
      searchScan(mobileNum);
      this.setState({ vewScan: false });
    } else {
      getScan(id);
      this.setState({ vewScan: true });
    }
  }
  setScan = (scan) => {
    this.setState({ currentScan: scan, vewScan: true });
  };
  setView = () => {
    this.setState({ vewScan: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
  };
  render() {
    const { searchScans, scan, loading, RequestScan } = this.props;
    const { vewScan, showModal } = this.state;
    const patient =
      (searchScans && searchScans.patient) || (scan && scan.patient);

    return (
      <Spin spinning={loading}>
        {patient && (
          <>
            <HeaderWrapper>
              <Container>
                <ProfileWrapper>
                  <div>
                    <h1>Patient Profile</h1>
                    <PatientProfileWrapper>
                      <div>
                        <ProfilePic
                          user={patient}
                          style={{ borderRadius: '50%' }}
                          width="50"
                          height="50"
                        />
                      </div>
                      <div>
                        <p className="name">
                          {CapitalizeFirstChart(patient.firstName)}{' '}
                          {CapitalizeFirstChart(patient.lastName)}
                        </p>
                        <p className="mobile">{patient.mobile}</p>
                      </div>
                    </PatientProfileWrapper>
                  </div>
                  <div>
                    <Add
                      onClickFunc={() => {
                        this.handelShowModal();
                      }}
                      title={'Request'}
                    />
                  </div>
                </ProfileWrapper>
              </Container>
            </HeaderWrapper>
            <Container>
              <Row>
                <Col span={16}>
                  {vewScan && scan && !isEmpty(scan) ? (
                    <>
                      <AddScan
                        scan={scan.consultant_scan_request}
                        setView={this.setView}
                        scanId={scan.id}
                      />
                    </>
                  ) : (
                    <>
                      {searchScans &&
                        searchScans.data.map((scan) => (
                          <Scan
                            scan={scan}
                            key={JSON.stringify(scan)}
                            patientId={searchScans.patient.id}
                            setScan={this.setScan}
                          />
                        ))}
                    </>
                  )}
                </Col>
                <Col span={8}>
                  <PatientInfo patient={patient} noImg={true} />
                </Col>
              </Row>
            </Container>
            <Modal
              title={'Request Lab Test'}
              image={Lab}
              showModal={showModal}
              handelCloseModal={this.handelCloseModal}
            >
              <>
                <RequestMedicalRecordForm
                  requestScan={RequestScan}
                  handelCloseModal={this.handelCloseModal}
                  showTypes={true}
                  showPrice={true}
                  patientId={patient.id}
                />
              </>
            </Modal>
          </>
        )}
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors, searchScans, scan, loading } = state.scans;
  return {
    errors,
    searchScans,
    scan,
    loading,
  };
};

const mapDispatchToProps = {
  searchScan: (mobile) => patientScansActions.searchScan(mobile),
  getScan: (id) => patientScansActions.getScan(id),
  RequestScan: (scan) => patientScansActions.RequestScan(scan),
};

export default connect(mapStateToProps, mapDispatchToProps)(ScansPage);
