import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import { ProfilePage } from './components';

const Profile = (props) => (
  <MainLayout footer="main" children={<ProfilePage {...props} />} {...props} />
);

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(Profile);
