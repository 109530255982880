const getScans = (page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/laboratory/scans${page ? `?page=${page}` : ''}`,
    requestOptions
  )
    .then((scans) => {
      return { error: false, scans };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getScan = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/laboratory/scans/${id}`, requestOptions)
    .then((scan) => {
      return { error: false, scan };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const searchScan = (mobile) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/laboratory/patient?mobile=${mobile}`, requestOptions)
    .then((scans) => {
      return { error: false, scans };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const storeScan = (scan) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(scan),
    redirect: 'follow',
  };

  return fetch(`/laboratory/scans`, requestOptions)
    .then((scan) => {
      return { error: false, scan };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const RequestScan = (scan) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(scan),
    redirect: 'follow',
  };

  return fetch(`/laboratory/scan-requests`, requestOptions)
    .then((scan) => {
      return { error: false, scan };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const uploadScan = (scan) => {
  const requestOptions = {
    method: 'POST',
    body: scan,
    redirect: 'follow',
  };

  return fetch(`/laboratory/scans/upload-file`, requestOptions)
    .then((scan) => {
      return { error: false, scan };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getUploadedScans = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/laboratory/scans/uploaded-history`, requestOptions)
    .then((scans) => {
      return { error: false, scans };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const patientScansServices = {
  getScans,
  getScan,
  searchScan,
  storeScan,
  RequestScan,
  uploadScan,
  getUploadedScans,
};

export default patientScansServices;
