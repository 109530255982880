import fetchIntercept from 'fetch-intercept';
import { history, handleErrors } from 'utils';
const API_URL = process.env.REACT_APP_API_URL;

const unregister = fetchIntercept.register({
  request: (url, config) => {
    // Modify the url or config here
    const check = url.startsWith('https') || url.startsWith('http');
    const user = JSON.parse(localStorage.getItem('user'));
    if (!check) {
      const myHeaders = new Headers();

      myHeaders.append('X-Api-Key', 'du7lik773nspohl0');

      if (typeof config.body === 'string') {
        // remove this header if conig.body formdata
        myHeaders.append('Content-Type', 'application/json');
      }
      if (user) {
        myHeaders.append('Authorization', `Bearer ${user.token}`);
      }
      config.headers = myHeaders;
    }

    url = check ? url : `${API_URL}${url}`;
    return [url, config];
  },

  requestError: (error) => {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: (response) => {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        let error;
        switch (response.status) {
          case 401:
            // auto logout if 401 response returned from api
            const logIn = history.location.pathname === '/login';
            logout();
            if (!logIn) {
              history.push('/login');
            }
            error = logIn
              ? ['email or password is incorrect, please try again!']
              : ['Please login First'];
            break;

          case 12163:
            error = 'Please check you internet connection!';
            break;
          case 400:
            error = handleErrors(data) || response.statusText;
            break;
          case 422:
            error = handleErrors(data.errors) || response.statusText;
            break;
          case 403:
            error = handleErrors(data.errors) || response.statusText;
            break;
          default:
            error = handleErrors(data.errors) || response.statusText;

            break;
        }
        return Promise.reject(error);
      }
      return data || response;
    });
  },

  responseError: (error) => {
    // Handle an fetch error
    return Promise.reject(handleErrors(error));
  },
});

export default unregister;

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('userType');
};
