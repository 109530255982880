import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from '@mous221/hk-components';
import {
  UserProfileCard,
  UserProfileNavigation,
  ChangePassword,
  UserInfo,
  MyAddress,
} from './';
import { userActions } from 'redux/actions';

const ProfilePageWrapper = styled.div`
  .profile-border {
    box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
    border-radius: 18px;
    border: solid 1px #e4e9f2;
    padding: 33px;
    background-color: #fff;
    &.ant-col {
      padding: 0;
      border: none;
      border-radius: 6px;
    }
  }
`;

class ProfilePage extends Component {
  static propTypes = {
    updateProfile: PropTypes.func,
    changePassword: PropTypes.func,
  };

  state = {
    currentView: 'userInfo',
    picture: false,
  };

  setCurrentView = (show) => {
    this.setState({ currentView: show });
  };

  render() {
    const { currentView } = this.state;
    const { changePassword, updateProfile } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    return (
      <ProfilePageWrapper>
        <Container>
          <Row>
            <Col className="profile-border" span={6}>
              <UserProfileCard user={user} />
              <UserProfileNavigation setCurrentView={this.setCurrentView} />
            </Col>
            {currentView === 'userInfo' && (
              <Col span={17} offset={1}>
                <UserInfo user={user} updateProfile={updateProfile} />
                <MyAddress user={user} updateProfile={updateProfile} />
              </Col>
            )}
            {currentView === 'changePassword' && (
              <Col span={17} offset={1}>
                <ChangePassword changePassword={changePassword} />
              </Col>
            )}
          </Row>
        </Container>
      </ProfilePageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (oldPassword, newPassword) =>
    dispatch(userActions.changePassword(oldPassword, newPassword)),
  getProfile: () => dispatch(userActions.getProfile()),
  updateProfile: (profile) => dispatch(userActions.updateProfile(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
