import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { ProfilePic, CapitalizeFirstChart } from '@mous221/hk-utils';

const UserProfileCardWrapper = styled.div`
  padding: 24px;
  text-align: center;
  h3 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
  button {
    background: #fff;
    border-radius: 6px;
    border: solid 0.5px #5cb3db;
    width: 100%;
    padding: 10px;
    color: #5cb3db;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    vertical-align: middle;
    .anticon {
      margin-right: 10px;
      font-size: 15px;
      line-height: 30px;

      vertical-align: bottom;
    }
  }
`;

const UserProfileCard = (props) => {
  const { name } = props.user;
  return (
    <UserProfileCardWrapper
      style={{
        backgroundColor: '#fff',
        boxShadow: '',
        textAlign: 'center',
        padding: 24,
      }}
    >
      <ProfilePic
        user={props.user}
        style={{ borderRadius: '50%' }}
        width="100"
        height="100"
      />
      <h3>{CapitalizeFirstChart(name)}</h3>
    </UserProfileCardWrapper>
  );
};

export default UserProfileCard;

UserProfileCard.propTypes = {
  user: PropTypes.object.isRequired,
};
