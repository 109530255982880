import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H1, PrimaryButton, Modal, ProfilePic } from '@mous221/hk-utils';
import { RequestMedicalRecordForm } from '@mous221/hk-components';
import { Form, Input } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import {
  Message,
  Message2X,
  CancelMessage,
  CancelMessage2X,
  Lab,
} from 'images';
import { patientScansActions } from 'redux/actions';

const SearchWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  padding: 15px;
  margin-top: 15px;
  background: #fff;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
  div {
    padding: 0 5px;
    h2 {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 22px;
      font-weight: 600;
      color: #5cb3db;
      margin-bottom: 5px;
      line-height: 1;
    }
    p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      color: #060c2b;
      line-height: 1;
      margin-bottom: 5px;
    }
  }
`;

const FormWrapper = styled.div`
  form {
    width: 100%;
    display: flex;
    button {
      padding: 10px;
    }
    .ant-form-item {
      width: 70%;
      margin-right: 30px;
      .ant-form-item-control {
        input {
          height: 49px;
        }
      }
    }
  }
`;

const NoResults = styled.div`
  text-align: center;
  min-width: 425px;
  padding-top: 26px;
  img {
    margin-bottom: 30px;
  }
  p {
    max-width: 230px;
    margin: auto;
    margin-bottom: 30px;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    color: #060c2b;
  }
  .no-patient {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #5cb3db;
    margin-bottom: 5px;
    max-width: none;
  }
`;
const NewRequestWrapper = styled.div`
  .Form-wrapper {
    padding: 0;
  }
  .modal__content {
    border-radius: 24px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
  }
`;
const SearchResults = styled.div`
  padding-top: 26px;
  text-align: center;
  img {
    margin-bottom: 10px;
  }
  .name {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
  }
  .mobile {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  p {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
    span {
      font-weight: bold;
    }
  }
`;
export class NewRequest extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    searchScan: PropTypes.func.isRequired,
    errors: PropTypes.string,
    searchScans: PropTypes.object,
    RequestScan: PropTypes.func,
  };
  state = {
    showModal: false,
    mobileNumber: null,
    showRequestModal: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      searchScan,
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        searchScan(values.mobile);
        this.setState({ mobileNumber: values.mobile });
        this.handelShowModal();
      }
    });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };
  handelShowRequestModal = () => {
    this.setState({
      showRequestModal: true,
    });
  };

  handelCloseModal = () => {
    this.setState({ showModal: false, showRequestModal: false });
  };

  render() {
    const {
      form: { getFieldDecorator },
      errors,
      searchScans,
      RequestScan,
    } = this.props;

    const { showModal, mobileNumber, showRequestModal } = this.state;
    return (
      <NewRequestWrapper>
        <div>
          <H1>New Request</H1>
          <SearchWrapper>
            <TitleWrapper>
              <div>
                <picture>
                  <source srcSet={`${Message2X} 2x`} />
                  <img src={Message} alt="Message" />
                </picture>
              </div>
              <div>
                <h2>Patient Mobile Number</h2>
                <p>Enter the Patient Mobile Number to see all lab request.</p>
              </div>
            </TitleWrapper>
            <FormWrapper>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item>
                  {getFieldDecorator('mobile', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input Patient Mobile Number!',
                      },
                    ],
                  })(<Input placeholder="Patient Mobile Number" />)}
                </Form.Item>
                <PrimaryButton>
                  <button htmltype="submit">Search</button>
                </PrimaryButton>
              </Form>
            </FormWrapper>
          </SearchWrapper>
        </div>
        <Modal
          title={''}
          showModal={showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            {errors && (
              <NoResults>
                <div>
                  <picture>
                    <source srcSet={`${CancelMessage2X} 2x`} />
                    <img src={CancelMessage} alt="Cancel Message" />
                  </picture>
                </div>
                <div>
                  <p className="no-patient">
                    There’s no patient with Mobile Number {mobileNumber}
                  </p>
                  <p>Please check the patient Mobile Number, and try again</p>
                </div>
                <PrimaryButton minWidth="420">
                  <button
                    onClick={() => {
                      this.handelCloseModal();
                    }}
                  >
                    Try again
                  </button>
                </PrimaryButton>
              </NoResults>
            )}
            {searchScans && (
              <SearchResults>
                <ProfilePic
                  user={searchScans.patient}
                  style={{ borderRadius: '50%' }}
                  width="100"
                  height="100"
                />
                <p className="name">
                  {searchScans.patient.firstName} {searchScans.patient.lastName}{' '}
                </p>
                <p className="mobile">{searchScans.patient.mobile}</p>
                <p>
                  {searchScans.meta.total > 0 ? (
                    <>
                      the patient has
                      <span className="scans-number">
                        {' '}
                        ({searchScans.meta.total}){' '}
                      </span>
                      Test Request
                    </>
                  ) : (
                    'The patient has not Scan Request'
                  )}
                </p>
                <PrimaryButton minWidth="420">
                  {searchScans.data.length > 0 ? (
                    <Link to={`/patient-scans/${searchScans.patient.mobile}`}>
                      View Details
                    </Link>
                  ) : (
                    <button
                      onClick={() => {
                        this.handelShowRequestModal();
                      }}
                    >
                      Add Scan request
                    </button>
                  )}
                </PrimaryButton>
              </SearchResults>
            )}
          </>
        </Modal>
        <Modal
          title={'Request Lab Test'}
          image={Lab}
          showModal={showRequestModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={RequestScan}
              handelCloseModal={this.handelCloseModal}
              showTypes={true}
              showPrice={true}
              patientId={
                searchScans && searchScans.patient && searchScans.patient.id
              }
            />
          </>
        </Modal>
      </NewRequestWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors, searchScans } = state.scans;
  return {
    errors,
    searchScans,
  };
};

const mapDispatchToProps = {
  searchScan: (mobile) => patientScansActions.searchScan(mobile),
  RequestScan: (scan) => patientScansActions.RequestScan(scan),
};

export default Form.create({ name: 'search-request' })(
  connect(mapStateToProps, mapDispatchToProps)(NewRequest)
);
