import { userConstants } from 'redux/actions/types';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { loggedIn: true, user }
  : { loggingIn: false, loading: false };

function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        loading: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        errors: { errors: action.error },
        loading: false,
      };
    case userConstants.UPDATA_PROFILE_REQUEST:
      return {
        updateingProfile: true,
        loading: true,
      };
    case userConstants.UPDATA_PROFILE_SUCCESS:
      return {
        updatedProfile: true,
        user: action.profile,
        loading: false,
      };
    case userConstants.UPDATA_PROFILE_FAILURE:
      return {
        errors: { ...state, errors: action.error },
        loading: false,
      };
    case userConstants.UPDATA_PROFILE_PICTURE_REQUEST:
      return {
        updateingProfilePic: true,
        loading: true,
      };
    case userConstants.UPDATA_PROFILE_PICTURE_SUCCESS:
      return {
        updatedProfilePic: true,
        user: action.profile,
        loading: false,
      };
    case userConstants.UPDATA_PROFILE_PICTURE_FAILURE:
      return {
        errors: { ...state, errors: action.error },
        loading: false,
      };
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        forgotPassword: true,
        loading: true,
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        forgotedPassword: true,
        massage: action.massage,
        loading: false,
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        errors: { ...state, errors: action.error },
        loading: false,
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        chanegPassword: true,
        loading: true,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        changedPassword: true,
        massage: action.massage,
        loading: false,
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        errors: { ...state, errors: action.error },
        loading: false,
      };
    case userConstants.GET_PROFILE_REQUEST:
      return {
        getProfile: true,
        loading: true,
      };
    case userConstants.GET_PROFILE_SUCCESS:
      return {
        gotProfile: true,
        user: action.profile,
        loading: false,
      };
    case userConstants.GET_PROFILE_FAILURE:
      return {
        errors: { ...state, errors: action.error },
        loading: false,
      };
    case userConstants.REFRESH_TOKENT_REQUEST:
      return {
        loading: true,
        getToken: true,
      };
    case userConstants.REFRESH_TOKENT_SUCCESS:
      return {
        gotToken: true,
        user: { ...state.user, token: action.token },
        loading: false,
      };
    case userConstants.REFRESH_TOKENT_FAILURE:
      return {
        errors: { ...state, errors: action.error },
        loading: false,
      };
    case userConstants.LOGOUT:
      return { loggingIn: false, loading: false };
    default:
      return state;
  }
}

export default authentication;
