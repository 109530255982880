import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'utils';
import {
  BaseLayout,
  LoginForm,
  FullPage,
  Container,
} from '@mous221/hk-components';
import { userActions, alertActions } from 'redux/actions';

class Login extends Component {
  componentWillMount() {
    localStorage.setItem('userType', 'laboratory');
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      history.push('/');
    }
  }
  render() {
    return (
      <BaseLayout>
        <FullPage>
          <Container>
            <LoginForm
              {...this.props}
              registerLink="/register"
              noRegister={true}
            />
          </Container>
        </FullPage>
      </BaseLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state.authentication;
  return {
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  errorAlert: (err) => dispatch(alertActions.error(err)),
  login: (email, password) => dispatch(userActions.login(email, password)),
  resetPassword: (email) => dispatch(userActions.forgotPassword(email)),
});

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);
export default connectedLoginPage;
