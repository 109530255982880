import React, { Component } from 'react';
import { Table } from 'antd';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Medical, Medical2X } from 'images';
import { CapitalizeFirstChart, ProfilePic } from '@mous221/hk-utils';

const ReportAction = styled.div`
  img {
    width: 40px;
    height: 30px;
  }
`;
const ResultTableWrapper = styled.div`
  margin-top: 15px;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background-color: #ffffff;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 15px;
  }
  .ant-pagination {
    margin-right: 10px;
  }
  .ant-table-thead > tr {
    background: #f9fafc;
  }
`;
const PatientName = styled.div`
  span {
    margin-left: 10px;
  }
`;
const columns = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Patient name',
    dataIndex: 'patient',
    key: 'name',
    render: (text) => (
      <PatientName>
        <ProfilePic
          user={text}
          width="36"
          height="36"
          style={{ borderRadius: '50%' }}
        />
        <span>{text && text.fullName}</span>
      </PatientName>
    ),
  },
  {
    title: 'Date/time',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'File',
    dataIndex: 'file',
    key: 'type',
    render: (record) => (
      <>
        {record && (
          <ReportAction>
            <a href={record} target="_blank" rel="noreferrer">
              <picture>
                <source srcSet={`${Medical2X} 2x`} />
                <img src={Medical} alt="Report" />
              </picture>
            </a>
          </ReportAction>
        )}
      </>
    ),
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (record) => (
  //     <ReportAction>
  //       <Link to={`/request/${record.id}?patientId=${record.patient.id}`}>
  //         <picture>
  //           <source srcSet={`${Medical2X} 2x`} />
  //           <img src={Medical} alt="Report" />
  //         </picture>
  //       </Link>
  //     </ReportAction>
  //   ),
  // },
];

export default class ResultTable extends Component {
  render() {
    const { finishedRequests, setPage, loading } = this.props;
    const data = finishedRequests.data.map((consultation, index) => ({
      key: index + finishedRequests.meta.from,
      patient: consultation.patient,
      date: consultation.date,
      price: consultation.price,
      id: consultation.id,
      type:
        consultation &&
        consultation.consultant_scan_request &&
        CapitalizeFirstChart(
          consultation.consultant_scan_request.scan_type
            .replaceAll('-', ' ')
            .replaceAll('_', ' ')
        ),
      file: consultation.scan_file,
    }));

    console.log('finishedRequests');
    console.log(finishedRequests);
    return (
      <ResultTableWrapper>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 15,
            total: finishedRequests.meta.total,
          }}
          onChange={(e) => setPage(e.current)}
        />
      </ResultTableWrapper>
    );
  }
}

ResultTable.propTypes = {
  finishedRequests: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
