import { alertActions, patientScansActions } from 'redux/actions';
import { userServices } from 'services';
import userConstants from './types';
import { history } from 'utils';

const login = (email, password) => {
  return (dispatch) => {
    dispatch(loginRequest({ email }));
    userServices.login(email, password).then((res) => {
      if (res.error) {
        dispatch(loginFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(loginSuccess(res));
        history.push('/');
      }
    });
  };
};

const loginRequest = (user) => ({ type: userConstants.LOGIN_REQUEST, user });
const loginSuccess = (user) => ({ type: userConstants.LOGIN_SUCCESS, user });
const loginFailure = (error) => ({
  type: userConstants.LOGIN_FAILURE,
  error,
});

const updateProfile = (profile) => {
  return (dispatch) => {
    dispatch(updateProfileRequest(profile));

    userServices.updateProfile(profile).then((res) => {
      if (res.error) {
        dispatch(updateProfileFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(alertActions.success('Yor Profile has been updated'));
        dispatch(updateProfileSuccess(res));
      }
    });
  };
};

const updateProfileRequest = (profile) => ({
  type: userConstants.UPDATA_PROFILE_REQUEST,
  profile,
});
const updateProfileSuccess = (profile) => ({
  type: userConstants.UPDATA_PROFILE_SUCCESS,
  profile,
});
const updateProfileFailure = (error) => ({
  type: userConstants.UPDATA_PROFILE_FAILURE,
  error,
});

const updateProfilePicture = (pic) => {
  return (dispatch) => {
    dispatch(updateProfilePictureRequest());

    userServices.updateProfilePicture(pic).then((res) => {
      if (res.error) {
        dispatch(updateProfilePictureFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateProfilePictureSuccess(res));
      }
    });
  };
};

const updateProfilePictureRequest = (pic) => ({
  type: userConstants.UPDATA_PROFILE_PICTURE_REQUEST,
  pic,
});
const updateProfilePictureSuccess = (profile) => ({
  type: userConstants.UPDATA_PROFILE_PICTURE_SUCCESS,
  profile,
});
const updateProfilePictureFailure = (error) => ({
  type: userConstants.UPDATA_PROFILE_PICTURE_FAILURE,
  error,
});

const forgotPassword = (email) => {
  return (dispatch) => {
    dispatch(forgotPasswordRequest());

    userServices.forgotPassword(email).then((res) => {
      if (res.error) {
        dispatch(forgotPasswordFailure(res.errors));

        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(alertActions.success('Check Your mail please'));
        dispatch(forgotPasswordSuccess(res));
      }
    });
  };
};

const forgotPasswordRequest = (email) => ({
  type: userConstants.FORGOT_PASSWORD_REQUEST,
  email,
});
const forgotPasswordSuccess = (massage) => ({
  type: userConstants.FORGOT_PASSWORD_SUCCESS,
  massage,
});
const forgotPasswordFailure = (error) => ({
  type: userConstants.FORGOT_PASSWORD_FAILURE,
  error,
});

const changePassword = (oldPassword, newPassword) => {
  return (dispatch) => {
    dispatch(changePasswordRequest());

    userServices.changePassword(oldPassword, newPassword).then((res) => {
      if (res.error) {
        dispatch(changePasswordFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(changePasswordSuccess(res));
        dispatch(alertActions.success('Your Password has Change'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const changePasswordRequest = () => ({
  type: userConstants.CHANGE_PASSWORD_REQUEST,
});
const changePasswordSuccess = (massage) => ({
  type: userConstants.CHANGE_PASSWORD_SUCCESS,
  massage,
});
const changePasswordFailure = (error) => ({
  type: userConstants.CHANGE_PASSWORD_FAILURE,
  error,
});

const getProfile = () => {
  return (dispatch) => {
    dispatch(getProfileRequest());

    userServices.getProfile().then((res) => {
      if (res.error) {
        dispatch(getProfileFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getProfileSuccess(res));
      }
    });
  };
};

const getProfileRequest = () => ({
  type: userConstants.GET_PROFILE_REQUEST,
});
const getProfileSuccess = (profile) => ({
  type: userConstants.GET_PROFILE_SUCCESS,
  profile,
});
const getProfileFailure = (error) => ({
  type: userConstants.GET_PROFILE_FAILURE,
  error,
});

const refreshToken = () => {
  return (dispatch) => {
    dispatch(refreshTokenRequest());

    userServices.refreshToken().then((res) => {
      if (res.error) {
        dispatch(refreshTokenFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(refreshTokenSuccess(res));
      }
    });
  };
};

const refreshTokenRequest = () => ({
  type: userConstants.REFRESH_TOKENT_REQUEST,
});
const refreshTokenSuccess = (token) => ({
  type: userConstants.REFRESH_TOKENT_SUCCESS,
  token,
});
const refreshTokenFailure = (error) => ({
  type: userConstants.REFRESH_TOKENT_FAILURE,
  error,
});

const logout = () => {
  userServices.logout();
  return (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(patientScansActions.clearData());
    dispatch(logoutSuccess());
  };
};

const logoutSuccess = () => ({ type: userConstants.LOGOUT });
const userActions = {
  login,
  logout,
  updateProfile,
  updateProfilePicture,
  changePassword,
  forgotPassword,
  getProfile,
  refreshToken,
};

export default userActions;
