import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { AddressInfoForm } from '@mous221/hk-components';

export class AddressInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFormIsOpen: false,
    };
  }

  handleEditFormToggle = () => {
    this.setState({ editFormIsOpen: !this.state.editFormIsOpen });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      updateProfile,
      form: { validateFields },
    } = this.props;
    const { editFormIsOpen } = this.state;

    validateFields((err, values) => {
      if (!err) {
        updateProfile(values);
        this.setState({ editFormIsOpen: !editFormIsOpen });
      }
    });
  };

  handleCancel = (e) => {
    e.preventDefault();
    const {
      form: { setFields },
      user,
    } = this.props;
    const { editFormIsOpen } = this.state;

    setFields({
      country: {
        value: user && user.country,
      },
      city: {
        value: user && user.city,
      },
      street: {
        value: user && user.street,
      },
    });
    this.setState({ editFormIsOpen: !editFormIsOpen });
  };

  render() {
    const { form, user } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <AddressInfoForm
          form={form}
          address={user}
          editFormIsOpen={false}
          handleEditFormToggle={null}
          handleCancel={this.handleCancel}
        />
      </Form>
    );
  }
}

export default Form.create({ name: 'user_info_form' })(AddressInfo);

AddressInfo.propTypes = {
  form: PropTypes.object,
  updateProfile: PropTypes.func,
  user: PropTypes.object,
};
