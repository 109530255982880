import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as scenes from 'scenes';
import { alertActions } from 'redux/actions';

import { GlobalStyles, history } from 'utils';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

import { PrivateRoute } from './PrivateRoute';
import 'utils/fetchIntercept';
class App extends Component {
  constructor(props) {
    super(props);

    const { clearAlerts } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      clearAlerts();
    });
  }
  state = {};
  static getDerivedStateFromProps({ alert }, state) {
    switch (alert.type) {
      case 'success':
        NotificationManager.success(alert.message);
        break;
      case 'warning':
        NotificationManager.warning(alert.message);
        break;
      case 'error':
        NotificationManager.error(alert.message);
        break;
      case 'info':
        NotificationManager.info(alert.message);
        break;
      default:
        break;
    }
    return {};
  }

  render() {
    return (
      <div>
        <GlobalStyles />
        <Router history={history}>
          <div>
            <PrivateRoute
              exact
              path="/patient-scans/:mobileNum"
              component={scenes.ScansPage}
            />
            <PrivateRoute
              exact
              path="/patient-scans/:mobileNum/:id"
              component={scenes.ScansPage}
            />
            <PrivateRoute exact path="/" component={scenes.HomePage} />
            <PrivateRoute
              exact
              path="/profile"
              component={scenes.ProfilePage}
            />
            <PrivateRoute
              exact
              path="/reports"
              component={scenes.ReportsPage}
            />
            <Route path="/login" component={scenes.LoginPage} />
          </div>
        </Router>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert } = state;
  return {
    alert,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAlerts: () => dispatch(alertActions.clear()),
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default connectedApp;

App.propTypes = {
  clearAlerts: PropTypes.func,
  alert: PropTypes.object,
};
