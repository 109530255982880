import React from 'react';
import styled from '@emotion/styled';
import { NewRequest, ActiveRequest } from './';

const Container = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;
export default function Home() {
  return (
    <Container>
      <NewRequest />
      <ActiveRequest />
    </Container>
  );
}
