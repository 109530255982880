import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from 'redux/actions';
import { Menu, Icon } from 'antd';
import { SwitchOffIcon } from 'images';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const UserProfileNavigationWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
`;

const SwitchOffIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #ec0707;
  span {
    font-family: AvenirNext;
    font-size: 15px;
    font-weight: 500;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const UserProfileNavigation = (props) => {
  const { setCurrentView, logout } = props;
  return (
    <>
      <UserProfileNavigationWrapper>
        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="vertical"
        >
          <Menu.Item
            key="1"
            onClick={() => {
              setCurrentView('userInfo');
            }}
          >
            <Icon type="info-circle" theme="filled" />
            My Info
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => {
              setCurrentView('changePassword');
            }}
          >
            <Icon type="lock" />
            Change Password
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              logout();
            }}
          >
            <Link to={'/login'}>
              <SwitchOffIconWrapper>
                <SwitchOffIcon height={24} width={24} />
                <span>Log Out</span>
              </SwitchOffIconWrapper>
            </Link>
          </Menu.Item>
        </Menu>
      </UserProfileNavigationWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

const mapStateToProps = (state) => ({});

const UserProfileNavigationCompoent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileNavigation);

UserProfileNavigation.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default UserProfileNavigationCompoent;
